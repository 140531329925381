
import React from 'react'
import ReactLoading from 'react-loading'

import './gallery.css'

const Gallery = (props)=>(
  <article className="Gallery" id="galeria">
    <h2 className="Gallery__Title" >{ props.gallery.title }</h2>
    <section className="Gallery__Images">
      {
        props.load
        ?
          props.picture.map(picture => (
            <figure className="Gallery__Image" key={picture.src}>
              <img src={picture.src} className="Gallery__Image-img" alt={picture.alt} />
            </figure>
          ))
          : <ReactLoading type="spin" color="rgb(22,121,209)" />
        }
    </section>
  </article>
)

export default Gallery