import React, { Component } from 'react'
import ReactLoaging from 'react-loading'
import './index.css';

import Header from './header'
import Banner from './banner'
import About from './about'
import Services from './services'
import Gallery from './gallery'
import Contact from './contact'
import Footer from './footer'

import { links, logo, menu } from '../data'
import db from '../firestoreConfig'

class App extends Component {
  constructor (...props) {
    super(...props)

    this.state = {
      links: [],
      logo: {},
      loadSections: false,
      sections: {},
      loadServices: false,
      services: {},
      loadGallery: false,
      gallery: {},
      menu: menu,
      menuState: false,
    }
    this.handleOnNav = this.handleOnNav.bind(this)
  }
  handleOnNav(e){
    this.setState({menuState: this.state.menuState ? false : true})
  }
  componentDidMount(){
    this.setState({
      links: links, 
      logo: logo,
    })
    db.collection('sections').orderBy('order').get().then(snapShots => {
      this.setState({ 
        sections: snapShots.docs.map( doc => (
          doc.data()
        )),
        loadSections: true
      })
    })
    db.collection('/sections/services/service').get().then(snapShots => {
      this.setState({
        services: snapShots.docs.map( doc => doc.data() ),
        loadServices: true
      })
    })
    db.collection('/sections/gallery/pictures').get().then(snapShots => {
      this.setState({
        gallery: snapShots.docs.map( doc => doc.data() ),
        loadGallery: true
      })
    })
  }
  render() {
    return (
        <div className="Main">
          <Header 
            logo={ logo } 
            links={ this.state.links } 
            handleOnNav={this.handleOnNav} 
            menuState={ this.state.menuState }
          />            
          {
            this.state.loadSections 
              ? <Sections 
                  sections={this.state.sections}
                  service={this.state.services}
                  loadServices={this.state.loadServices}
                  gallery={this.state.gallery}
                  loadGallery={this.state.loadGallery}

                /> 
              : <Load/>              
          }
          <Footer />
        </div>
    );
  }
}

const Load = ()=>(
  <div className="Poster">
    <ReactLoaging type="spin" color="rgb(22,121,209)" className="loading" />  
  </div>
)

const Sections = (props) =>(
  <main>
    <Banner banner={ props.sections[0] } />
    <About about={props.sections[1]} />
    <Services 
      services={ props.sections[2] } 
      load={props.loadServices}
      service={props.service}
    />
    <Gallery 
      gallery={ props.sections[3] } 
      load={props.loadGallery}
      picture={props.gallery}
    />
    <Contact contact={ props.sections[4] } />
  </main>
)

export default App;