import React from 'react'

import './about.css'

const About = ( props )=>(
  <article className="About" id="quienes-somos">
    <div className="About__Title">
      <h2>{props.about.title}</h2>
    </div>
    <div className="About__Image">
      <img src={props.about.img[0]} alt={props.about.img[1]} />
    </div>
    <div className="About__Text">
      <p>{props.about.text}</p>
    </div>
  </article>
)

export default About