import React from 'react'

import './contact.css'

const Contact = (props)=>(
  <article className="Contact Poster" style={{backgroundImage: `url(${props.contact.background})`}}><div className="Contact--container Poster--filter" id="contacto">
      <h2 className="Contact__Title">{props.contact.title}</h2>
      <Form contact={props.contact} />      
      <Map map={props.contact.map} />
    </div>
  </article>
)

const Form = (props) => (
  <section className="Contact__Form">
    <form action={`https://formspree.io/eventosylogistica.net@hotmail.com`} method="POST">
      <input type="text" className="form-control" name="nombre" placeholder="Nombre" required />
      <input type="email" className="form-control" name="email" placeholder="Email" required />
      <textarea name="mensaje" cols="30" rows="10" placeholder="Contenido del Mensaje" className="form-control" required />
      <input type="submit" className="btn btn-default" value="Enviar" />
      <input type="hidden" name="_language" value="es" />
    </form>
    <Date />
    <Social />
  </section>
)

const Date = () => (
  <ul className="Contact__Form-date">
    <li>
      <a href="tel:5712306141"><i className="fas fa-phone"></i> (571) 230 6141 </a>
      -
      <a href="tel:3123900714"><i className="fas fa-mobile-alt"></i> 312 390 0714 </a>
    </li>
    <li><a href="mailto:eventosylogistica.net@hotmail.com"><i className="far fa-envelope"></i> eventosylogistica.net@hotmail.com</a></li>
    <li><p><i className="fas fa-map-marked-alt"></i> - Bogota, Colombia.</p></li>
  </ul>
)

const Social = () => (
  <ul className="Contact__Form-social">
    <li><a href="https://www.facebook.com/eventosylogistica.net/" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f" ></i></a></li>
    <li><a href="https://twitter.com/eventosylogisti" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter" ></i></a></li>
    <li><a href="https://www.youtube.com/channel/UC-5ecMsjM8nvJyI7X04VIwA" target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube" ></i></a></li>
  </ul>
)

const Map =  ()  => (
  <section className="Contact__Map">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3976.905899371913!2d-74.13564225008619!3d4.610861796633952!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f9eb708705a85%3A0x5ce405462b755076!2seventos+y+logistica!5e0!3m2!1ses!2sco!4v1531663347873" frameBorder="0" allowFullScreen title="Mapa eventos y logistica"></iframe>
  </section>
)

export default Contact