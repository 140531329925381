import React from 'react'

import './footer.css'

const Footer = () => (
  <footer className="Footer">
    <p>&copy; Eventos y Logistica 2019 | Todos los derechos reservados.</p>
    <span>Desarrollado por <a href="http://devHector.Ga" target="_blank" rel="noopener noreferrer">developer Hector Garzón</a></span>
  </footer>
)

export default Footer;