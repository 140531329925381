import firebase from 'firebase/app'
import 'firebase/firestore'

firebase.initializeApp({
  apiKey: "AIzaSyCyubORR2WEUB8gLRyd6PTdzPYmD3Fhh_I",
  authDomain: "eventosylogistica-4386b.firebaseapp.com",
  projectId: "eventosylogistica-4386b"
})
let db = firebase.firestore()

export default db