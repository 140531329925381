import React from 'react'

import './header.css';

const Header = (props)=>(
  <header className="Header">
    <section className="Header-container">
      <Logo logo={props.logo} />
      <Button handleOnNav={props.handleOnNav}/>
      <aside className={props.menuState ?`Panel is-active`:`Panel`} >
        <Nav links={props.links} handleOnNav={props.handleOnNav}/>
      </aside>
    </section>
  </header>
)

const Button = (props) =>(
  <div className="Panel-button" onClick={props.handleOnNav}>
    <button className="hamburguer hamburguer--spring" type="button">
      <span className="hamburguer-box">
        <span className="hamburguer-inner"></span>
      </span>
    </button>
  </div>
)

const Logo = ( props )=>(
  <h1 className="Logo">
    <a href={props.logo.href} className="Logo-link" style={{backgroundImage: `url(${props.logo.imagotipo})` }}>{props.logo.name}</a>
  </h1>
)

const Nav = ( props )=>(
  <nav className="Menu">
    <ul className="Menu-listItem">
      {
        props.links.map(link => (
          <li className="Menu-item" key={link.name} onClick={props.handleOnNav}><a href={link.url} className="Menu-link">{link.name}</a></li>
        ))
      }
    </ul>
</nav>
)

export default Header