import React from 'react'
import ReactLoading from 'react-loading'

import './services.css'

const Services = (props)=>(
  <article className="Poster Service" id="servicios" style={{backgroundImage: `url(${props.services.background})`}}>
    <div className="Poster--filter">
      <h2 className="Service__Title">{props.services.title}</h2>
      <section className="Service__Items">
        {
          props.load ? 
            props.service.map(service => (
              <article className="Service__Item" key={service.title}>
                <h3 className="Service__Item-title">{service.title}</h3>
                <img className="Service__Item-figure" src={service.img[0]} alt={service.img[1]} />
                <p className="Service__Item-text">{service.text}</p>
              </article>
            )) 
            : <ReactLoading type="spin" color="#fff"/>
        }
      </section>
    </div>
  </article>
)

export default Services