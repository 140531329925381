import React from 'react'

import './poster.css'

const Banner = (props)=>(
  <article className="Poster u-firstContent" style={{backgroundImage: `url(${props.banner.background})`}} id="inicio">
    <div className="Poster-title">
      <img src={props.banner.img[0]} alt={props.banner.img[1]} /> 
      <h2 className="Poster-title">{props.banner.title}</h2>
    </div>
  </article>
)

export default Banner